<template>
  <v-container class="bg-glass-effect px-3 py-0" fluid>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog.show" persistent max-width="400px">
          <v-card :style="dialog.style" rounded="lg">
            <v-row justify="center" class="ma-0 px-0 pt-4" style="min-height: 80vh;">
              <v-col cols="12" align-self="start">
                <v-row>
                  <v-col cols="12">
                    <v-btn icon :color="dialog.colorDefault" @click="closeDialog()">
                      <v-icon large>fas fa-times</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col col="12">
                    <h2
                        class="pb-5 generic-title"
                        style="text-align: center; font-weight: bold;"
                        :style="{color: dialog.colorDefault}"
                    >{{ notification.title }}</h2>
                    <p
                        class="px-5 generic-text"
                        style="text-align: center; font-size: 20px"
                        :style="{color: dialog.colorDefault}"
                        v-html="getNotificationBody"></p>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" align-self="end">
                <v-btn class="btn btn-great px-0 mx-0" :color="dialog.colorDefault" block x-large
                       rounded @click="onClickGoTo()">Acessar Mensagens</v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SocialNetworkService from "../../services/socialnetwork/SocialNetworkService";
export default {
  name: "pushNotification",
  data: () => ({
    dialog: {
      message: '',
      show: true,
      colorDefault: 'primary',
      style: {
        color: '#532E88',
        backgroundColor: '#EEEDF7',
        minHeight: '80vh'
      }
    },
    notification: {
      title: 'Notificação',
      body: 'Mensagem de notificação via Push Notification.',
      messageId: 0,
      messageDomainOrigin: null,
      sender: null
    }
  }),
  beforeCreate() {
    this._socialNetworkService = new SocialNetworkService();
  },
  async created() {
    await this.loadNotificationData();
  },
  computed: {
    getNotificationBody() {
      if(this.notification && this.notification.body) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###abrirAtendimento", "[Abrir Atendimento]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###reiniciarChat", "[Nova Conversa]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###socilitarReembolso", "[Solicitar Reembolso]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###consultarRede", "[Consultar Rede]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###duvidaCoparticipacao", "[Dúvida Sobre Coparticipação]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###consultarCobertura", "[Consultar Cobertura]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###segundaViaCartao", "[Segunda Via Carteirinha]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###abrirProtocoloCovid", "[Abrir Protocolo Covid]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###chatBotNao", "[Não]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("###chatBotSim", "[Sim]");
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.notification.body = this.notification.body.replaceAll("\\n", "<br />");
      }
      return this.notification.body;
    }
  },
  methods:{
    async loadNotificationData(){
      if(this.$route.params && this.$route.params.notification){
        let { notification } = this.$route.params;

        this.notification.title = notification.title;
        this.notification.body = notification.body;
        if(notification.data.messageId){
          const sender = { id: notification.data.messageSenderEntityId }
          this.notification.messageId = notification.data.messageId;
          this.notification.messageDomainOrigin = notification.data.messageDomainOrigin;
          this.notification.sender = sender;
          await this.markReadPushNotification();
        }
      }
    },
    onClickGoTo(){
      if(this.notification.sender && this.notification.messageId && this.notification.messageDomainOrigin !== 'CHATBOT'){
        this.$router.push({ name: 'notifications', params: { sender: this.notification.sender, messageId: this.notification.messageId } });
      }else{
        this.$router.push({ name: 'message', params: { isPushNotification: true } });
      }
    },
    closeDialog(){
      this.$router.back();
    },
    async markReadPushNotification(){
      if(this.notification.messageId > 0){
        await this._socialNetworkService.MarkReadByMessageId(this.notification.messageId).then(() => {})
      }
    },
  }
}
</script>

<style scoped>

/*background effect blur*/
.bg-glass-effect{
  background: rgba(255,255,255,1.0) !important;
  mix-blend-mode: normal !important;
  backdrop-filter: blur(100px) !important;
  height: 100vh;
}

.btn {
  text-transform: capitalize;
  font-weight: bold;
  font-family: Open Sans;
  font-size: 15px;
}

.btn-great {
  font-size: 18px;
}

.centered-input >>> input {
  text-align: center;
}

</style>


